.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.organization_backgrounds__item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.organization_backgrounds__item>div.MuiGrid-root {
    max-width: 80%;
}

.organization_backgrounds__item>div.MuiGrid-root>div {
    height: 100px;
}


.organization_backgrounds__item>label.MuiFormControlLabel-root>span.MuiCheckbox-root {
    padding: 2px;
}

#aircall-container {
    position: absolute;
    z-index: 99999999;
    right: 2rem;
    top: 2rem;
    border: 1px solid #afafa9;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 28%) 0px 0px 2px;
}

#aircall-container.hidden {
    display: none;
}

#aircall-container #close-phone {
    background-color: white;
    position: relative;
    border-radius: 4px;
    float: right;
    width: 100%;
    text-align: right;
}

#aircall-container #aircall iframe {
    border: 0;
    border-radius: 4px;

}

ol-radio-group::part(button-group__base) {
    gap: var(--ol-spacing-medium);
    justify-content: center;
    flex-wrap: wrap;
}

ol-radio-group[size='small']::part(button-group__base) {
    gap: var(--ol-spacing-small);
}

ol-radio-group[size='large']::part(button-group__base) {
    gap: var(--ol-spacing-large);
}

ol-radio-group ol-radio-button::part(button) {
    color: #000;
    border-color: #000;
    position: relative;
    border-radius: 48px;
}

ol-radio-group ol-radio-button::part(label) {
    padding-block: 4px;
}

ol-radio-group ol-radio-button::part(prefix) {
    padding-inline-start: 4px;
}

ol-radio-group ol-radio-button::part(suffix) {
    padding-inline-end: 4px;
}

ol-radio-group ol-radio-button:hover:not([disabled], [checked])::part(button) {
    color: var(--ol-color-primary-950);
    background: transparent;
}

ol-radio-group ol-radio-button:hover:not([disabled], [checked])::part(button)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--ol-color-primary-950);
    border-radius: 48px;
}

ol-radio-group ol-radio-button::part(button--checked) {
    color: var(--ol-color-neutral-100);
    background-color: var(--ol-color-primary-600);
    border-color: var(--ol-color-primary-600);
}

ol-radio-group ol-radio-button:hover::part(button--checked) {
    color: var(--ol-color-neutral-100);
    border-color: var(--ol-color-primary-600);
}


ol-calendar::part(base) {
    width: 100%;
    border: none;
    --ol-border-radius-large: 24px;

}