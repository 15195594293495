/* --- Google fonts Begin --- */
/* Montserrat */
/* montserrat-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('../public/fonts/montserrat-v26-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../public/fonts/montserrat-v26-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../public/fonts/montserrat-v26-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/montserrat-v26-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/montserrat-v26-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/montserrat-v26-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Material Icons */
/* material-icons-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/material-icons-v142-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Material Icons Outlined */
/* material-symbols-outlined-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100;
  src: url('../public/fonts/material-symbols-outlined-v192-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* material-symbols-outlined-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 200;
  src: url('../public/fonts/material-symbols-outlined-v192-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* material-symbols-outlined-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/material-symbols-outlined-v192-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* material-symbols-outlined-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/material-symbols-outlined-v192-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* --- Google fonts End ----- */
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body,
html {
    height: 100%;
    font-family: "Montserrat";
}

body {
    display: flex;
    height: 100%;
    flex-direction: column;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.footer {
    height: 58px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.72rem;
    margin-bottom: 0;
    margin-top: auto;
}

.MuiBreadcrumbs-root {
    background-color: #ffffff;
    padding: 0.5rem 0.9rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    margin-bottom: 16px !important;
}

.MuiBreadcrumbs-li {
    font-size: 0.7rem;
}

.MuiBreadcrumbs-li:hover {
    text-decoration: none;
}

.MuiBreadcrumbs-li:last-child {
    color: black;
}

.opacity5 {
    opacity: 0.5;
}

.cursorNotAllowed {
    cursor: not-allowed !important;
}

/* START arrow icon */
.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.rightArr {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.leftArr {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

@keyframes quiet {
    15% {
        transform: scaleY(.6);
    }

    40% {
        transform: scaleY(.4);
    }

    60% {
        transform: scaleY(.8);
    }
}

@keyframes normal {
    25% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(.4);
    }

    75% {
        transform: scaleY(.7);
    }
}

@keyframes loud {
    30% {
        transform: scaleY(1);
    }

    60% {
        transform: scaleY(.4);
    }

    85% {
        transform: scaleY(1.2);
    }
}

.boxContainer {
    background-color: #00000080;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    height: 26px;
    --boxSize: 1.5px;
    align-items: center;
    gap: 2px;
    width: 26px;
    padding: 2px;
}

.box {
    transform: scaleY(.4);
    height: 70%;
    width: var(--boxSize);
    background: #eee;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
}

.box1 {
    animation-name: quiet;
}

.box2 {
    animation-name: normal;
}

.box3 {
    animation-name: quiet;
}

.box4 {
    animation-name: loud;
}

.box5 {
    animation-name: quiet;
}

fade-enter {
    opacity: 0;
    transition: opacity 700ms;

}

fade-enter-active {
    opacity: 1;
    transition: opacity 700ms;
}


/* END arrow icon */