
 .main{
  width: 100%;
  height: 100vh;
  display: flex;
}



.imageContainer {
  width: 50%;
  height: 100%;

}

.imageContainer img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contentContainer{
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login {
  margin: auto;
  max-width: 320px;
  margin-right: 16px;
  margin-left: 16px;
}


.buttonWrapper{
	position: relative;
	width: 100%;
}

.buttonWrapper button{
	width: 100%;
}

.loading{
	position: absolute;
	top: 50%;
    right: 12px;
	margin-top: -8px;
}

.altLogin{
	padding-top: 20px;
	text-align: center;
	font-size: 0.8rem;
	width: 100%;
	border-bottom: 1px solid lightgray;
	line-height: 0.1em;
	margin: 10px 0 20px;
}

div.altLogin > span {
	background:#fff;
	padding:0 10px;
	color: lightgray;
 }

.myAccountInput {
	width: fit-content;
}

.myAccountButton {
	width: 200px;
}

.resetLink {
	margin-top: .8rem;
	font-size: .8rem;
}

.backLink {
	margin-top: 1rem;
	font-size: .8rem;
}

.loadingContainer {
	height: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 768px){
	.main{
		flex-direction: column;
	}
	.imageContainer {
	  width: 100%;
	  height: auto;
	}
	.contentContainer {
	  width: 100%;
	  height: auto;
	}
	.imageContainer img{
		max-height: 300px;
		object-position: top;
	}
	.myAccountInput {
		width: 100%;
	}
}
