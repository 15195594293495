/* Base Styling */

.phone {
    float: right;
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;

}

.phone svg {
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 6px;
    margin-right: 10px;
}

.phone svg path {
    fill: #6ac975;
}

.not_animated svg path {
    fill: #ccc;
}

.phone_anim svg path:nth-child(2) {
    -webkit-animation: header-phone 1s infinite cubic-bezier(.1, .57, .5, 1);
    animation: header-phone 1s infinite cubic-bezier(.1, .57, .5, 1);
}

.phone_anim svg path:nth-child(3) {
    -webkit-animation: header-phone2 1s infinite cubic-bezier(.1, .57, .5, 1);
    animation: header-phone2 1s infinite cubic-bezier(.1, .57, .5, 1);
}

/* Animate phone */

@-webkit-keyframes header-phone {
    0%, 30% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    80% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@keyframes header-phone {
    0%, 30% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    80% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes header-phone2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    70%, 90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@keyframes header-phone2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    70%, 90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}